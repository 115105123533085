import 'bootstrap';
window.simpleLightbox = require('simplelightbox');
window.AOS = require('aos');

$(function() {
    $('.gallery a').simpleLightbox();
    $(window).on('load', function(){
        $('[data-src]').each(function(){
            var $this = $(this),
                src = $(this).data('src');
            $this.attr('src', src);
        });
    });
});

$('a[href^="#"]').click(function(){
    let the_id = $(this).attr("href");
    $('html, body').animate({
        scrollTop:$(the_id).offset().top
    }, 'slow');
    return false;
});

$(window).scroll(function() {
    let scrollTop = $(this).scrollTop();

    $('.main-image .background').css({
        opacity: function() {
            let elementHeight = $(this).height(),
                opacity = ((1 - (elementHeight + scrollTop) / elementHeight) * 0.8) + 1;

            return opacity;
        }
    });
});

AOS.init();

$(window).on('load', function() {
    $('#status').fadeOut();
    $('#preloader').delay(250).fadeOut('slow');
    $('body').delay(550).css({'overflow':'visible'});
});

window.onload = function() {

    var pageTitle = document.title;
    var attentionMessage = '* Wróć do nas!';
    var blinkEvent = null;

    document.addEventListener('visibilitychange', function(e) {
        var isPageActive = !document.hidden;

        if(!isPageActive){
            blink();
        }else {
            document.title = pageTitle;
            clearInterval(blinkEvent);
        }
    });

    function blink(){
        blinkEvent = setInterval(function() {
            if(document.title === attentionMessage){
                document.title = pageTitle;
            }else {
                document.title = attentionMessage;
            }
        }, 100);
    }
};